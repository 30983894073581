<template>
  <div>
    <div class="search-bar flex justify-between items-center">
      <a-form-model layout="inline" class="flex-1" ref="searchRef"
                    :model="searchForm"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol">
        <a-form-model-item prop="type" label="城市" style="width:170px;">
          <a-select v-model="searchForm.outlets_city_adcode" placeholder="选择城市"
              @change="handlerSearchRemind">
            <a-select-option
              v-for="(item, index) in manageCityList"
              :key="index"
              :value="item.adcode"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="outlets_id" label="门店" style="width:220px;">
          <a-select show-search allowClear placeholder="门店名"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="handlerSuggestOutlets"
                    v-model="searchForm.outlets_id"
                    @change="handlerSearchRemind">
            <a-select-option v-for="(item, index) in outletsList"
                    :key="index"
                    :value="item.outlets_id">{{item.outlets_name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="remind_time_arr" label="提醒时间">
          <a-range-picker v-model="searchForm.remind_time_arr" style="width:260px;"
                    valueFormat="YYYY-MM-DD"
                    @change="handlerSearchRemind" />
        </a-form-model-item>
      </a-form-model>
      <div>
        <a-button class1="ml-2" @click="resetForm">重置</a-button>
        <a-button class="ml-2" @click="handlerSearchRemind" type="primary">搜索</a-button>
      </div>
    </div>

    <div class="flex items-center mt-2 mb-2">
      <span class="text-lg font-bold p-3">城市门店补货提醒</span>
    </div>

    <base-table
        :columnsData="columns"
        :tableData="tableData"
        :total="total"
        :getListFunc="initDataRemind"
        :page.sync="searchForm.page"
        :pageSize.sync="searchForm.page_count">
        <template #operation="{record}">
          <a-button type="link" @click="handlerMember(record)">查看</a-button>
        </template>
    </base-table>
      
  </div>
</template>

<script>

import { getOutletsList } from '@/api/customer'
import { getStockRemind } from '@/api/regional/analysis'

import { isCityManage, getMarketCityList } from "@/utils/authData";

export default {
  components: {

  },
  data() {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      columns: [],
      columns_remind: [
        {
          title: '门店名',
          dataIndex: 'outlets_name',
          align: 'center',
          width: "30%",
        },
        {
          title: '商品名称',
          dataIndex: 'goods_name',
          align: 'center',
          width: "40%",
        },
        {
          title: '提醒时间',
          dataIndex: 'remind_time',
          align: 'center',
          width: "30%",
        },
        {
          title: "用户",
          dataIndex: "operation",
          align: "center",
          width: 100,
          slots: { customRender: "operation" },
        },
      ],

      isCityManage: isCityManage(),
      manageCityList: [],

      // 补货提醒
      searchForm: {
        outlets_city_adcode: undefined,
        outlets_id: undefined,
        remind_time_arr: [],
        time_start: "",
        time_end: "",
        page: 1,
        page_count: 40,
      },
      total: 0,
      tableData: [],
      timerOutlets: null,
      outletsList: [],

      loading: false,
    };
  },
  async mounted() {
    if(this.isCityManage){
      this.columns = this.columns_remind.filter(el=>!['operation'].includes(el.dataIndex))
    }else{
      this.columns = this.columns_remind
    }

    this.searchForm.remind_time_arr = [
      this.getToday(), 
      this.getToday()
    ]

    this.manageCityList = await getMarketCityList()
    if(this.manageCityList.length == 1){
      this.searchForm.outlets_city_adcode = this.manageCityList[0].adcode
    }

    if(this.searchForm.outlets_city_adcode){
      this.handlerSearchRemind()
    }
  },
  methods: {
    handlerSearchRemind(){
      if(!this.searchForm.outlets_city_adcode){
        this.$message.info("请选择城市")
        return
      }

      this.searchForm.page = 1
      this.initDataRemind();
    },
    resetForm() {
      this.$refs.searchRef.resetFields();
      this.initDataRemind();
    },
    async initDataRemind(){
      const params = {
        ...this.searchForm,
        time_start: "",
        time_end: "",
      }
      if(params.remind_time_arr.length == 2){
        params.time_start = params.remind_time_arr[0]
        params.time_end = params.remind_time_arr[1]
      }
      const { data, code } = await getStockRemind(params);
      if (code === 0) {
        this.tableData = data.list;
        this.total = data.total_count
      }
    },

    handlerMember(record){
      const newPage = this.$router.resolve({
        name: "memberDetail",
        query: { member_id: record.member_id },
      });
      window.open(newPage.href, "_blank");
    },

    async handlerSuggestOutlets (keyword) {
      if(this.timerOutlets){
        clearTimeout(this.timerOutlets)
        this.timerOutlets = null
      }
      this.timerOutlets = setTimeout(()=>{
        this.getSuggestOutlets(keyword)
      }, 100)
    },
    async getSuggestOutlets(keyword){
      if(!this.searchForm.outlets_city_adcode){
        this.outletsList = []
        return
      }
      if(!keyword){
        this.outletsList = []
        return
      }
      
      const params = {
        ignore_close: 2,
        // outlets_status: 3, // 1-关店，2-未发布，3-营业
        outlets_city_adcode: this.searchForm.outlets_city_adcode,
        outlets_name: keyword,
        count: 10,
      }
      const { data, code } = await getOutletsList(params)
      if (code == 0) {
        this.outletsList = data.list
        // this.outletsList = data.list.map(el=>{
        //   let status = el.outlets_status == 2 ? "【未发布】" : (el.outlets_status == 3 ? "【营业中】" : "")
        //   el.showName = status + el.outlets_name
        //   return el
        // })
      }
    },

    getToday(){
      const today = new Date()
      return this.formatDate(today)
    },
    formatDate(date){
      let year = date.getFullYear()
      let month = date.getMonth()
      let day = date.getDate()
      month = month+1
      if(month < 10){
        month = "0"+month
      }
      if(day < 10){
        day = "0"+day
      }
      return year+"-"+month+"-"+day
    },
    
  },
};
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>
